import styled from '@emotion/styled';

import { styles } from '@onehope/design-system';
import {
  withStyles,
  MenuItem,
  SelectCore,
  Slider,
  Text,
  theme,
} from '@onehope/design-system-v2';
import { MuiInput, MuiInputSelect } from '@onehope/design-system-ohw';

import { ButtonProps, DefaultOption } from '../EventDetailsForm/index.styles';

const { cssConstants } = styles;

export const LongInput = styled(MuiInput)`
  && {
    max-width: 460px;
    width: 100%;
    margin-bottom: 24px;
  }
`;

export const BoldText = styled(Text)`
  && {
    font-weight: 600;
  }
`;

export const Title = styled(Text)`
  max-width: 460px;
  width: 100%;
`;

export const StateContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 460px;
  padding-bottom: 27px;
`;

export const DonationContainer = styled.div`
  max-width: 460px;
  margin: 40px 0;
`;

export const LocationContainer = styled.div`
  max-width: 460px;
  margin: 0px 0 40px 0;
`;

export const DonationAmount = styled.div`
  max-width: 460px;
  /* font-family: PlayfairDisplay; */
  font-size: 18px;
  font-weight: bold;
  line-height: 1.31;
  letter-spacing: -0.5px;
  color: ${cssConstants.textColor};
  margin-top: 16px;
`;

export const DonationBaselineContainer = styled.div`
  max-width: 460px;
  padding-left: 85px;
`;

export const IconContainer = styled.div`
  margin-left: 13px;
  margin-bottom: -15px;
  margin-top: -18px;
`;

export const DonationBaseline = styled.div`
  width: 46px;
  height: 18px;
  font-size: 11px;
  line-height: 1.64;
  text-align: center;
  color: ${cssConstants.textColor};
`;

export const CustomNonProfitWrapper = styled.div`
  margin-top: 24px;
`;

export const NonProfitContainer = styled.div`
  padding-left: 16px;
`;

export const NonProfitTitle = styled.div`
  max-width: 230px;
  width: 100%;
  height: 18px;
  /* font-family: ProximaNova; */
  font-size: 14px;
  font-weight: bold;
  line-height: 1.29;
  color: ${theme.palette.primary.main};
  padding-bottom: 2px;
  min-width: 0;
  white-space: nowrap;
  overflow: hidden;
  text-wrap: normal;
  text-overflow: ellipsis;
  @media only screen and (min-width: 375px) {
    max-width: 280px;
  }
  @media only screen and (min-width: 460px) {
    max-width: 340px;
  }
`;

export const NonProfitDescription = styled.div`
  max-width: 230px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-wrap: normal;
  text-overflow: ellipsis;
  font-size: 11px;
  font-weight: normal;
  line-height: 1.45;
  color: ${theme.palette.primary.main};
  word-wrap: break-word;
  @media only screen and (min-width: 375px) {
    max-width: 280px;
  }
  @media only screen and (min-width: 460px) {
    max-width: 340px;
  }
`;

export const WrapContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: -24px;
  @media only screen and (min-width: 460px) {
    flex-direction: row;
    margin-bottom: 0;
  }
`;

export const Select = styled(SelectCore)<{ showDefault: boolean }>`
  .MuiSelect-selectMenu {
    white-space: pre-wrap;
  }
  .MuiSelect-select {
    :focus {
      background-color: ${({ showDefault }: ButtonProps) =>
        showDefault
          ? `${cssConstants.neutral5} !important`
          : 'white !important'};
    }
  }
  && {
    width: 100%;
    max-width: 460px;
    height: 72px;
    padding: 0;
    white-space: pre-wrap;
  }
`;

export const Partner = styled(MuiInputSelect)<ButtonProps>`
  && {
    width: 140px;
    height: 50px;
    font-family: ${cssConstants.font};
    font-size: 14px;
    font-weight: bold;
    border-radius: 4px;
    line-height: 18px;
    color: ${cssConstants.textColor};
    border: ${({ showDefault }: ButtonProps) =>
      showDefault ? 'none' : `1px solid ${cssConstants.primaryColor}`};
    background-color: ${({ showDefault }: ButtonProps) =>
      showDefault ? `${cssConstants.neutral5}` : 'white'};
    :hover,
    :focus {
      background-color: ${({ showDefault }: ButtonProps) =>
        showDefault ? `${cssConstants.neutral5}` : 'white'};
    }
    :first-of-type {
      border: none;
    }
  }
`;

export const DefaultMenuItem = styled(MenuItem)`
  && {
    max-width: 460px;
    width: 100%;
    height: 72px;
  }
`;

export const PartnerDefaultOption = styled(DefaultOption)`
  width: 100%;
  opacity: 1;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
`;

export const Option = styled(MenuItem)`
  && {
    max-width: 460px;
    width: 100%;
    height: 72px;
  }
`;

export const DonationSlider = withStyles({
  root: {
    color: '#0091ff',
    height: 4,
    padding: '15px 0',
  },
  thumb: {
    height: 16,
    width: 16,
    border: 'solid 4px #0091ff',
    backgroundColor: cssConstants.neutralBackgroundColor,
    marginTop: -6,
    '&:focus,&:hover,&$active': {
      boxShadow: 'unset',
    },
  },
  active: {},
  track: {
    height: 8,
    marginTop: -2,
    borderRadius: 100,
  },
  rail: {
    height: 8,
    marginTop: -2,
    borderRadius: 100,
    backgroundColor: '#e5f4ff',
    opacity: 1.0,
  },
})(Slider);

export const Details = styled.div`
  margin-bottom: -40px;
`;
